import { useCallback, useEffect, useState } from 'react';

const cachedScripts: string[] = [];

const useScript = (src: string): [boolean | undefined, boolean | undefined] => {
  const [loaded, setLoaded] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  const handleLoad = () => {
    setLoaded(true);
    setError(false);
  };

  const handleError = () => {
    setLoaded(false);
    setError(true);
  };

  const addScriptWithListeners = useCallback(() => {
    cachedScripts.push(src);

    const script = document.createElement('script');

    script.src = src;
    script.async = true;

    script.addEventListener('load', handleLoad);
    script.addEventListener('error', handleError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', handleLoad);
      script.removeEventListener('error', handleError);
    };
  }, [src]);

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      handleLoad();
    }

    addScriptWithListeners();
  }, [addScriptWithListeners, src]);

  return [loaded, error];
};

export default useScript;
